import Vue from "vue";
import Vuex from "vuex";
import api from "@/store/api.js";
import navigation from "@/store/navigation";
import user from "@/store/user";
import crews from "@/store/crews";
import artists from "@/store/artists";
import organizers from "@/store/organizers";
import emails from "@/store/emails";
import events from "@/store/events";
import takings from "@/store/takings";
import deposits from "@/store/deposits";
import certificate from "@/store/certificate";
import expenses from "@/store/expenses";
import users from "@/store/users";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      reducer(val) {
        return {
          //Save only the user in state
          user: val.user,
        };
      },
    }),
  ],
  modules: {
    user: user,
    users: users,
    navigation: navigation,
    crews: crews,
    artists: artists,
    organizers: organizers,
    events: events,
    emails: emails,
    takings: takings,
    deposits: deposits,
    certificate: certificate,
    expenses: expenses,
  },
  state: {
    test_email: {
      email: process.env.VUE_APP_TEST_EMAIL,
    },
    api_lang: null,
    loading: false,
    currentMsg: null,
    breadCrumb: null,
    suppressMsg: false,
    callback: {
      code: null,
    },
    login_uri: {
      url: process.env.VUE_APP_HYDRA_URL,
      params: {
        audience: "",
        client_id: process.env.VUE_APP_HYDRA_CLIENT_ID,
        max_age: 3600,
        nonce: " xekvmuthjobjmiizejbutcqh",
        prompt: "",
        redirect_uri: process.env.VUE_APP_HYDRA_REDIRECT_URI,
        response_type: "code",
        scope: process.env.VUE_APP_HYDRA_SCOPE,
        state: "bmnlhrhbloactuulqwdcmdzg",
      },
    },
    login_url: process.env.VUE_APP_LOGIN_URL,
    defaults: {
      currencies: [
        {
          label: "€",
          value: "EUR",
        },
        {
          label: "$",
          value: "USD",
        },
        {
          label: "Fr.",
          value: "CHF",
        },
      ],
    },
  },
  mutations: {
    code(state, val) {
      state.callback.code = val;
    },
    user(state, val) {
      state.user.id = val.id;
    },
    currentMsg(state, value) {
      state.currentMsg = value;
    },
    suppressMsg(state, value) {
      state.suppressMsg = value;
    },
    breadCrumb(state, value) {
      state.breadCrumb = value;
    },
    loadingFlow(state) {
      state.loading = !state.loading;
    },
    api_lang(state, value) {
      state.api_lang = value;
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    login_uri(state) {
      state.login_uri.params["language"] =
        localStorage.language !== undefined ? localStorage.language : "de";

      let query_params = [];
      for (let d in state.login_uri.params)
        query_params.push(
          encodeURIComponent(d) +
            "=" +
            encodeURIComponent(state.login_uri.params[d])
        );

      return state.login_uri.url + "?" + query_params.join("&");
    },
    logout_url(state) {
      return state.login_url + "/logout";
    },
    register_url(state) {
      return state.login_url + "/register";
    },
    session(state) {
      if (state.user.current !== null) {
        return true;
      }
      return false;
    },
    currentMsg(state) {
      return state.currentMsg;
    },
    suppressMsg(state) {
      return state.suppressMsg;
    },
    breadCrumb(state) {
      return state.breadCrumb;
    },
    loadingFlow(state) {
      return state.loading;
    },
    currencies(state) {
      return state.defaults.currencies;
    },
    api_lang(state) {
      return state.api_lang;
    },
  },
  actions: {
    test_login({ state, commit }) {
      return new Promise((resolve, reject) => {
        api
          .post("/auth/testlogin", state.test_email)
          .then((response) => {
            commit("user/login", response.data.payload);
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    callback({ state, commit }, data) {
      commit("code", data.data);
      return new Promise((resolve, reject) => {
        api
          .post("/auth/callback", state.callback)
          .then((response) => {
            commit("user/login", response.data.payload);
            //commit("user/profile/all", response.data.payload.profile)
            //commit("user/address/all", response.data.payload.address)
            //commit("user/address/all_edit", response.data.payload.address)
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    refresh({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/auth/refresh")
          .then((response) => {
            commit("user/login", response.data.payload);
            resolve();
          })
          .catch((error) => {
            commit("user/logout");
            reject(error);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/auth/logout")
          .then((response) => {
            commit("user/logout", response.data.payload);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
});
